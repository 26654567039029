<template>
  <div>
    <v-container>
      <h2 class="text-h5 mb-4"> FAQ Sections &nbsp;&nbsp; (Total: {{ sections.total }})</h2>
      <v-card class="mb-4" style="padding: 20px">
        <v-row>
          <v-col md="3">
            <v-text-field
              v-model="filters.search_keyword"
              label="Search Keyword"
              outlined
              @change="fetchFaqSections()"
              dense
              placeholder="Search Keyword"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-select
              style="margin-right: 10px"
              v-model="filters.status"
              dense
              :items="[{name : 'All' , value : ''},{ name : 'Active', value : 1}, {name : 'In-Active' , value : 0}]"
              item-text="name"
              item-value="value"
              @change="fetchFaqSections()"
              label="Status"
              outlined
            >
            </v-select>
          </v-col>
          <v-col md="6" style="text-align: right;">
            <v-btn
          color="primary"
          @click="create_section_dialog = true"
          class="tet-white mr-2"
          small
          >Create New</v-btn
        >
          </v-col>
        </v-row>
      </v-card>
      <v-data-table :headers="headers" :items="sections.data" class="elevation-1" disable-pagination hide-default-footer>
        <template v-slot:body="{ items }">
          <tbody v-if="!loading_data">
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.name }}</td>
              <td>
                <v-chip small class="ma-2" color="green" text-color="white" v-if="item.status == '1'"
                  >Active</v-chip
                >
                <v-chip small class="ma-2" color="red" text-color="white" v-else>Inactive</v-chip>
              </td>
              <td>
                <v-btn fab small color="primary darken-1" class="table-action--button" @click="openEditDialog(item.id)">
                <v-icon>{{ mdiSquareEditOutline }}</v-icon>
              </v-btn>

              <v-btn fab small color="primary darken-1" class="table-action--button" @click="openDeleteDialog(item.id)">
                <v-icon>{{ mdiTrashCanOutline }}</v-icon>
              </v-btn>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="7" class="text-center" style="padding: 80px 0px">
                <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-pagination
        v-model="sections.current_page"
        class="my-4"
        @input="handlePageChange"
        :length="sections.last_page"
        :total-visible="7"
      ></v-pagination>
    </v-container>


    <!-- CREATE DIALOG BOX -->
    <v-dialog v-model="create_section_dialog" width="500">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2"> Create New FAQ Section </v-card-title>

        <v-card-text>
          <div style="padding: 20px">
              <v-text-field
                type="text"
                v-model="new_section.name"
                class="w-full"
                label="Section Name"
                outlined
              ></v-text-field>
               <v-text-field
                type="number"
                v-model="new_section.position"
                class="w-full"
                label="Section position"
                outlined
              ></v-text-field>
             <v-select
              v-model="new_section.status"
              dense
              :items="section_status"
              label="Status"
              item-text="name"
              item-value="value"
              outlined></v-select>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="create_section_dialog = false"> CANCEL </v-btn>
          <v-btn color="primary" text @click="createSection" :loading="is_section_create_clicked" :disabled="is_section_create_clicked">
            <v-progress-circular v-if="is_section_create_clicked" indeterminate color="primary" size="20"></v-progress-circular>
            <span v-else>Done</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- EDIT DIALOG BOX -->
    <v-dialog v-model="edit_section_dialog" width="500">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2"> Edit FAQ Section </v-card-title>

        <v-card-text>
          <div style="padding: 20px">
              <v-text-field
                type="text"
                v-model="edit_section.name"
                class="w-full"
                label="Section Name"
                value="edit_section.name"
                outlined
              ></v-text-field>
              <v-text-field
                type="number"
                v-model="edit_section.position"
                class="w-full"
                label="Section position"
                value="edit_section.position"
                outlined
              ></v-text-field>
             <v-select
              v-model="edit_section.status"
              dense
              :items="section_status"
              label="Status"
              item-text="name"
              item-value="value"
              outlined></v-select>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="delete_section_dialog = false"> CANCEL </v-btn>
          <v-btn color="primary" text @click="updateSection(edit_section.id)" :loading="is_section_create_clicked" :disabled="is_section_create_clicked">
            <v-progress-circular v-if="is_section_create_clicked" indeterminate color="primary" size="20"></v-progress-circular>
            <span v-else>Done</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- DELETE CONFIRMATION DIALOG BOX -->
    <v-dialog v-model="delete_section_dialog" width="500">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2"> Delete FAQ Section </v-card-title>

        <v-card-text>
          Are you sure you want to delete  {{ delete_section.name }}  section? Deleting the section will delete all the content that falls under the section.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="edit_section_dialog = false"> CANCEL </v-btn>
          <v-btn color="primary" text @click="deleteSection(delete_section.id)" :loading="is_section_create_clicked" :disabled="is_section_create_clicked">
            <v-progress-circular v-if="is_section_create_clicked" indeterminate color="primary" size="20"></v-progress-circular>
            <span v-else>Confirm Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiLogin, mdiLoading, mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js'
import moment from "moment";
import { mapActions } from "vuex";
import data from '@/views/dashboard/datatable-data';

export default {
  data() {
    return {
      loading_data: false,
      is_first: true,
      filters: {
        search_keyword: '',
        status: ''
      },
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions' },
      ],
      sections: {
        current_page: 1,
        data: [],
      },
      new_section : {
        name : '',
        status : 0,
        position: 0
      },
      edit_section : {
        id: 0,
        name: '',
        status : 0,
        position: 0
      },
      delete_section : {
        id: 0,
        name: '',
        status : 0
      },
      create_section_dialog: false,
      section_status : [{name: 'Active', value : 1}, {name : 'In-Active' , value : 0}],
      //icons
      mdiLogin: mdiLogin,
      mdiLoading: mdiLoading,
      mdiSquareEditOutline: mdiSquareEditOutline,
      mdiTrashCanOutline: mdiTrashCanOutline,
      is_section_create_clicked: false,
      edit_section_dialog: false,
      delete_section_dialog: false

    }
  },
  mounted() {
    this.fetchFaqSections()
  },
  methods: {
    ...mapActions(["LoginAsClient"]),
    handlePageChange(value) {
      this.fetchFaqSections()
    },
    async fetchFaqSections() {
        this.loading_data = true
        await axios.get('admin/faq/sections', {
          params: {
            page: this.sections.current_page,
            search: this.filters.search_keyword,
            status: this.filters.status
          },
        }).then((res) => {
          this.sections = res.data
        }).catch((err) => {
          console.log(err);
        })
        this.loading_data = false

    },

    async createSection() {
        this.loading_data = true
        await axios.post('admin/faq/section', {
           name : this.new_section.name,
           status : this.new_section.status,
           position : this.new_section.position
        }).then((res) => {
            this.fetchFaqSections();
            this.create_section_dialog = false;
        }).catch((err) => {
          console.log(err);
        });
        this.new_section =   {
                name : '',
                status : 0
              }
        this.loading_data = false

    },


  async deleteSection(faq) {
        this.loading_data = true
    await axios.delete('admin/faq/section/' + faq)
          .then((res) => {
            this.fetchFaqSections();
            this.delete_section_dialog = false;
          }).catch((err) => {
            console.log(err);
          });
        this.loading_data = true

  },

  async updateSection(faq) {
        this.loading_data = true
        await axios.put('admin/faq/section/' + faq, {
          name: this.edit_section.name,
          status : this.edit_section.status,
           position : this.edit_section.position
        })
        .then((res) => {
          this.fetchFaqSections();
          this.edit_section_dialog = false
        }).catch((err) => {
          console.log(err);
        this.loading_data = false

        })

  },

  openEditDialog(faq_id) {
    var faq = this.sections.data.find(item => item.id === faq_id);
    this.edit_section.id = faq.id;
    this.edit_section.name = faq.name;
    this.edit_section.status = faq.status;
    this.edit_section.position = faq.position;
    this.edit_section_dialog = true;
  },

  openDeleteDialog(faq_id) {
    var faq = this.sections.data.find(item => item.id === faq_id);
    this.delete_section.id = faq.id;
    this.delete_section.name = faq.name;
    this.delete_section.status = faq.status;
    this.delete_section.position = faq.position;
    this.delete_section_dialog = true;
  }
  },

}
</script>
